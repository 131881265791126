<template>
  <div class="d-flex flex-column justify-content-center items-center">
    <qrcode-vue :value="value" :size="size" :level="level"></qrcode-vue>
    <div :class="fontClasses">{{ code }}</div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: "QRCode",
  props: {
    value: {
      required: true,
    },
    size: {
      required: false,
      default: 200,
    },
    level: {
      required: false,
      default: 'H',
    },
    code: {
      required: true,
      default: '123456789101112',
    },
    fontClasses: {
      required: false,
      default: 'text-xs'
    }
  },
  components: {
    QrcodeVue
  }
}
</script>

<style scoped>

</style>